import React from 'react';
import Layout from "../../layout/Layout";
import SEO from "../../components/seo";

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";

import enMessages from '../../i18n/legal/pciDSSCertified/en.js'
import ruMessages from '../../i18n/legal/pciDSSCertified/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const RequisitePage = ({ pathContext: { locale } }) => {
    const section = 'pciDSSCertified';

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            <div>
                {messages[locale].content.map(item => (
                  <RectangleLink
                    title={item.title}
                    label={item.label}
                    link={item.link}
                    target="_blank"
                  />
                ))}
            </div>

        </TemplateLegalPage>
    )
};

export default RequisitePage;
