import pciDSSCertified from '../../../assets/files/en/Certificate_PCI_DSS_LLC_2024.pdf';

export default {
    seo: {
        title: 'PCI DSS certified | Bank 131',
        description: 'PCI DSS certified'
    },
    title: 'PCI DSS certified',
    content: [
         {
             label: 'Posting date 15.08.2024',
             title: 'Certificate PCI DSS.PDF',
             link: pciDSSCertified
         }
    ]
}